import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68')
];

export const server_loads = [2];

export const dictionary = {
		"/(auth)/(app)": [14,[2,3]],
		"/(auth)/(app)/account": [57,[2,3]],
		"/(no-auth)/(background-hue)/account/login": [~59,[13]],
		"/(no-auth)/(background-hue)/account/reset-password": [60,[13]],
		"/(no-auth)/(background-hue)/account/reset-password/confirm": [61,[13]],
		"/(no-auth)/(background-hue)/account/sign-up": [62,[13]],
		"/(no-auth)/(background-hue)/account/verify-email": [63,[13]],
		"/(auth)/(app)/(admin)/cloud/api-docs": [15,[2,3,4]],
		"/(auth)/(app)/(admin)/cloud/home": [16,[2,3,4]],
		"/(auth)/(app)/(admin)/data/fhir": [17,[2,3,4]],
		"/(auth)/(app)/(admin)/data/loinc": [18,[2,3,4]],
		"/(auth)/(app)/(admin)/data/sam": [19,[2,3,4]],
		"/(auth)/(app)/(admin)/data/snomed-ct": [20,[2,3,4]],
		"/(auth)/(app)/(admin)/evals": [21,[2,3,4]],
		"/(auth)/(app)/(admin)/evals/runs": [25,[2,3,4]],
		"/(auth)/(app)/(admin)/evals/runs/[run_uuid]": [26,[2,3,4]],
		"/(auth)/(app)/(admin)/evals/[uuid]": [22,[2,3,4,5]],
		"/(auth)/(app)/(admin)/evals/[uuid]/expected-output": [23,[2,3,4,5]],
		"/(auth)/(app)/(admin)/evals/[uuid]/runs/[run_uuid]": [24,[2,3,4,5]],
		"/(no-auth)/patient-demo": [65],
		"/(no-auth)/patient-demo/privacy": [66],
		"/(no-auth)/patient-demo/terms": [67],
		"/(auth)/(app)/(practice)/patients": [31,[2,3,6]],
		"/(auth)/(app)/(practice)/patients/[id]": [32,[2,3,6,9]],
		"/(auth)/(app)/(practice)/patients/[id]/carepaths": [33,[2,3,6,9]],
		"/(auth)/(app)/(practice)/patients/[id]/consultations": [34,[2,3,6,9]],
		"/(auth)/(app)/(practice)/patients/[id]/diabetes": [35,[2,3,6,9]],
		"/(auth)/(app)/(practice)/patients/[id]/diagnosis": [36,[2,3,6,9]],
		"/(auth)/(app)/(practice)/patients/[id]/documents": [37,[2,3,6,9]],
		"/(auth)/(app)/(practice)/patients/[id]/documents/[uuid]": [38,[2,3,6,9]],
		"/(auth)/(app)/(practice)/patients/[id]/family": [39,[2,3,6,9]],
		"/(auth)/(app)/(practice)/patients/[id]/incapacities": [40,[2,3,6,9]],
		"/(auth)/(app)/(practice)/patients/[id]/kmehr": [41,[2,3,6,9]],
		"/(auth)/(app)/(practice)/patients/[id]/kmehr/[transaction_id]": [42,[2,3,6,9]],
		"/(auth)/(app)/(practice)/patients/[id]/medication": [43,[2,3,6,9]],
		"/(auth)/(app)/(practice)/patients/[id]/parameters": [44,[2,3,6,9]],
		"/(auth)/(app)/(practice)/populations": [45,[2,3,6,10]],
		"/(auth)/(app)/(practice)/populations/contactreports": [46,[2,3,6,10]],
		"/(auth)/(app)/(practice)/populations/diabetes": [47,[2,3,6,10,11]],
		"/(auth)/(app)/(practice)/populations/diabetes/carepath/candidates": [48,[2,3,6,10,11]],
		"/(auth)/(app)/(practice)/populations/diabetes/carepath/identified": [49,[2,3,6,10,11]],
		"/(auth)/(app)/(practice)/populations/diabetes/identified/gestational": [50,[2,3,6,10,11]],
		"/(auth)/(app)/(practice)/populations/diabetes/identified/prediabetes": [51,[2,3,6,10,11]],
		"/(auth)/(app)/(practice)/populations/diabetes/identified/type-1": [52,[2,3,6,10,11]],
		"/(auth)/(app)/(practice)/populations/diabetes/identified/type-2": [53,[2,3,6,10,11]],
		"/(auth)/(app)/(practice)/populations/incapacities": [54,[2,3,6,10]],
		"/(auth)/(app)/(practice)/populations/nephrology/carepath/candidates": [55,[2,3,6,10,12]],
		"/(auth)/(app)/(practice)/populations/nephrology/carepath/identified": [56,[2,3,6,10,12]],
		"/(auth)/(app)/(practice)/(license)/practice": [28,[2,3,6,7]],
		"/(auth)/(app)/(practice)/(license)/practice/documents/[uuid]": [29,[2,3,6,7,8]],
		"/(auth)/(app)/(practice)/(license)/practice/documents/[uuid]/data-extractions/[analysis_uuid]": [30,[2,3,6,7,8]],
		"/(no-auth)/sentry/test": [68],
		"/(auth)/(app)/support": [58,[2,3]],
		"/(auth)/(app)/(admin)/users": [27,[2,3,4]],
		"/(no-auth)/(background-hue)/welcome": [64,[13]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,

	reroute: (() => {})
};

export { default as root } from '../root.svelte';